import React from 'react';
import { Autocomplete } from '@material-ui/lab';
import { IoClose } from 'react-icons/io5';
import { FiChevronDown } from 'react-icons/fi';
import { makeStyles, Theme } from '@material-ui/core';

import { Company } from '@vyce/core/src/types';
import { appAutocompleteUseStyles } from '@vyce/core/src/components/controlled-inputs/ControlledAutocomplete';
import { AppTextField } from '@vyce/core/src/components/inputs/AppTextField';

interface Props {
  companies: Company[];
  onCompanyChange: any;
  defaultValue?: Company;
  disableClearable?: boolean;
}

export const useInnerStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .MuiInputBase-root': {
      border: 'unset',
    },
  },
}));

export const CompanyFilter: React.FC<Props> = ({ companies, onCompanyChange, ...rest }) => {
  const autocompleteClasses = appAutocompleteUseStyles({ withoutLabel: true });
  const classes = useInnerStyles();
  return (
    <Autocomplete
      options={companies}
      classes={autocompleteClasses}
      className={classes.root}
      onChange={onCompanyChange}
      popupIcon={<FiChevronDown size="25px" color="#A0A3BD" />}
      closeIcon={<IoClose size="25px" color="#A0A3BD" />}
      getOptionLabel={(option: any) => option?.name ?? ''}
      fullWidth
      renderInput={params => <AppTextField {...params} fullWidth label="Company" />}
      {...rest}
    />
  );
};
