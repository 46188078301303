import React, { useEffect, useState } from 'react';

import { Layouts } from 'react-grid-layout';
import noop from 'lodash/noop';

import { WidgetItem } from '@vyce/core/src/types';
import { getFromLS, saveToLS } from '@vyce/core/src/utils/local-storage';
import { CheckerWidget } from '@vyce/core/src/components/CheckerWidget';
import { WeatherWidget } from '@vyce/core/src/views/widgets/WeatherWidget';
import { EmailVerificationWidget } from '@vyce/core/src/views/widgets/EmailVerificationWidget';
import { AppDashboard, getInitialLayouts } from '@vyce/core/src/components/Dashboard/AppDashboard';
import { ReCAPTCHAComponent } from '@vyce/core/src/components';
import { useRecaptcha } from '@vyce/core/src/hooks';

import { useTypedSelector } from 'src/hooks';
import { PaymentsWidget } from './PaymentsWidget';
import { CompleteProfileWidget } from './CompleteProfileWidget';

export const EMPLOYEE_DASHBOARD_ITEMS: WidgetItem[] = [
  {
    name: 'Verify your Email',
    type: 'email',
    system_name: 'email',
    layout: { i: 'email', x: 0, y: 0, w: 2, h: 5, minH: 4, minW: 2 },
    is_active: true,
  },
  {
    name: 'Right To Work',
    type: 'rtw',
    system_name: 'rtw',
    layout: { i: 'rtw', x: 2, y: 0, w: 2, h: 5, minH: 4, minW: 2 },
    is_active: true,
  },
  {
    name: 'Passport',
    type: 'passport',
    system_name: 'passport',
    layout: { i: 'passport', x: 4, y: 0, w: 2, h: 5, minH: 4, minW: 2 },
    is_active: true,
  },
  {
    name: 'Your latest payments...',
    type: 'list',
    system_name: 'payments',
    layout: { i: 'payments', x: 0, y: 8, w: 8, h: 9, minH: 4, minW: 2, isResizable: true },
    is_active: true,
  },
  {
    name: 'Weather',
    type: 'weather',
    system_name: 'weather',
    layout: { i: 'weather', x: 3, y: 0, w: 5, h: 8, minH: 3, minW: 3, isResizable: true },
    is_active: true,
  },
  {
    name: 'Complete your profile!',
    type: 'profile',
    system_name: 'profile',
    layout: { i: 'profile', x: 0, y: 0, w: 3, h: 8, minH: 3, minW: 3, isResizable: true },
    is_active: true,
  },
];

const LS_ITEMS_KEY = 'employeeDashboardItems';
const LS_LAYOUT_KEY = 'employeeDashboardLayouts';

export const EmployeeDashboard = () => {
  const [items, setItems] = useState<WidgetItem[]>(getFromLS(LS_ITEMS_KEY) || EMPLOYEE_DASHBOARD_ITEMS);
  const [layouts, setLayouts] = useState<Layouts>(
    getFromLS(LS_LAYOUT_KEY) || getInitialLayouts(EMPLOYEE_DASHBOARD_ITEMS)
  );
  const { user } = useTypedSelector(state => state);
  const { reCaptchaRef, getReCaptchaToken } = useRecaptcha();

  useEffect(() => {
    let items = EMPLOYEE_DASHBOARD_ITEMS;
    if (user.id_verification?.verified) {
      items = items.filter(item => item.type !== 'passport');
    }
    if (user.rtw_verification?.verified) {
      items = items.filter(item => item.type !== 'rtw');
    }
    if (user.email_verified) {
      items = items.filter(item => item.type !== 'email');
    }
    setItems(items);
  }, [user]);

  const onLayoutChange = (_: any, allLayouts: Layouts) => {
    setLayouts(allLayouts);
    saveToLS(LS_LAYOUT_KEY, allLayouts);
    saveToLS(LS_ITEMS_KEY, items);
  };

  const getWidget = (item: WidgetItem) => {
    switch (item.type) {
      case 'rtw':
        return {
          component: CheckerWidget,
          props: {
            title: 'Right To Work unverified',
            subtitle:
              'Verifying your RTW is important for any companies you work with on Vyce and will enable you to work and be paid through Vyce',
            redirectTo: '/profile/documents',
            type: 'error',
            buttonText: 'Go to profile to verify',
          },
        };

      case 'passport':
        return {
          component: CheckerWidget,
          props: {
            title: 'Passport unverified',
            subtitle:
              'Verifying your Passport (ID) is important for any companies you work with on Vyce and will enable you to work and be paid through Vyce',
            redirectTo: '/profile/documents',
            type: 'error',
            buttonText: 'Go to profile to verify',
          },
        };

      case 'email':
        return {
          component: EmailVerificationWidget,
          props: {
            userId: user.uuid,
            getReCaptchaToken,
          },
        };
      case 'list':
        return {
          component: PaymentsWidget,
          props: {},
        };
      case 'weather':
        return { component: WeatherWidget, props: {} };
      case 'profile':
        return { component: CompleteProfileWidget, props: {} };
    }
  };

  return (
    <>
      <AppDashboard
        isWidgetsClosable={false}
        layouts={layouts}
        onLayoutChange={onLayoutChange}
        widgets={items}
        onWidgetStatusChange={noop}
        getWidget={getWidget}
      />
      <ReCAPTCHAComponent ref={reCaptchaRef} />
    </>
  );
};
