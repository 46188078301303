import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { FiDownloadCloud } from 'react-icons/fi';
import type { GridColDef } from '@mui/x-data-grid';
import { Box, useTheme } from '@material-ui/core';

import { EnrichedPayslips } from '@vyce/core/src/types';
import { getPayslipsPDFRequest, getPayslipsRequest } from '@vyce/core/src/api/pay';
import { GRID_PAGE_SIZE } from '@vyce/core/src/constants';
import { formatTableDate, getTaxPeriod } from '@vyce/core/src/utils/dates';
import { AppIconButton, AppLink, AppDataGrid, AppMobileDataGrid } from '@vyce/core/src/components';
import type { RowSchemaProps } from '@vyce/core/src/components';
import { currencyFormatter, generateFileLinkAndSave } from '@vyce/core/src/utils';
import { DeviceContext } from '@vyce/core/src/contexts';
import { NotificationContext } from '@vyce/core/src/contexts/notificationContext';
import { useTable } from '@vyce/core/src/hooks/useTable';
import { WidgetWrapper, WidgetWrapperProps } from '@vyce/core/src/components/Dashboard/WidgetWrapper';

type EnrichedPayslipsProps = EnrichedPayslips & { id: string };

const mobileColumnNames = [
  { headerName: 'Period', flex: '0 1 49%' },
  { headerName: 'Take Home', flex: '0 1 44%' },
  {
    headerName: 'View',
    position: 'absolute',
    top: '7px',
    right: '33px',
  },
];

export const PaymentsWidget = (props: WidgetWrapperProps) => {
  const { handleServerError } = useContext(NotificationContext);
  const [payslips, setPayslips] = useState<EnrichedPayslipsProps[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { offset, total, page, setTotal, handlePageChange } = useTable({});

  const theme = useTheme();
  const { isMobile } = useContext(DeviceContext);

  const pageCount = Math.ceil(total / GRID_PAGE_SIZE);

  const columns: GridColDef[] = [
    {
      field: 'tax_year_end',
      headerName: 'Period',
      disableColumnMenu: true,
      valueFormatter: params => getTaxPeriod(params.row.tax_week_end, params.row.tax_year_end),
      flex: 1,
      sortable: false,
      minWidth: 150,
    },
    {
      field: 'pay_date',
      headerName: 'Payment Date',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      valueGetter: params => formatTableDate(params.row?.pay_date, false),
      minWidth: 120,
    },
    {
      field: 'company',
      headerName: 'Company',
      renderCell: params => (
        <Box display="flex" width="100%">
          <AppLink to={`/payments/${params.row.company?.name}_${params.row.company?.company_id}`}>
            {params.row?.company?.name}
          </AppLink>
        </Box>
      ),
      flex: 2,
      sortable: false,
      disableColumnMenu: true,
      minWidth: 150,
    },
    {
      field: 'gross',
      headerName: 'Gross',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      valueFormatter: params => currencyFormatter.format(params.row.gross),
      minWidth: 150,
    },
    {
      field: 'additions',
      headerName: 'Additions',
      sortable: false,
      valueFormatter: params => currencyFormatter.format(params.row.additions),
      flex: 1,
      disableColumnMenu: true,
      minWidth: 90,
    },
    {
      field: 'deductions',
      headerName: 'Deductions',
      valueFormatter: params => currencyFormatter.format(params.row.deductions),
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      minWidth: 90,
    },
    {
      field: 'net',
      headerName: 'Take Home',
      valueFormatter: params => currencyFormatter.format(params.row.net),
      flex: 1,
      minWidth: 90,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: '',
      headerName: ' ',
      width: 80,
      hideSortIcons: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: params => (
        <Box display="flex" width="100%">
          <AppIconButton variant="primary" onClick={() => downloadPdf(params.row.pay_run_line_id)}>
            <FiDownloadCloud size={24} color={theme.palette.type === 'dark' ? '#14142B' : '#610BEF'} />
          </AppIconButton>
        </Box>
      ),
    },
  ];

  const mobileOptions: RowSchemaProps<EnrichedPayslipsProps>[][] = [
    [
      {
        field: 'tax_year_end',
        titleName: 'Period',
        valueFormatter: row => getTaxPeriod(row.tax_week_end, row.tax_year_end),
      },
      {
        field: 'pay_date',
        titleName: 'Payment Date',
        valueGetter: row => formatTableDate(row?.pay_date, false),
      },
    ],
    [
      {
        field: 'company',
        titleName: 'Company',
        renderCell: row => (
          <Box display="flex" width="100%">
            <AppLink to={`/payments/${row.company?.name}_${row.company?.company_id}`}>
              {row?.company?.name}
            </AppLink>
          </Box>
        ),
      },
    ],
    [
      {
        field: 'gross',
        titleName: 'Gross',
        valueFormatter: row => currencyFormatter.format(row.gross),
      },
      {
        field: 'additions',
        titleName: 'Additions',
        valueFormatter: row => currencyFormatter.format(row.additions),
      },
    ],
    [
      {
        field: 'deductions',
        titleName: 'Deductions',
        valueFormatter: row => currencyFormatter.format(row.deductions),
      },
      {
        field: 'net',
        titleName: 'Take Home',
        valueFormatter: row => currencyFormatter.format(row.net),
        extraComponent: row => (
          <Box display="flex" width="100%">
            <AppIconButton variant="primary" isSmall onClick={() => downloadPdf(row.pay_run_line_id)}>
              <FiDownloadCloud size={14} color={theme.palette.type === 'dark' ? '#14142B' : '#610BEF'} />
            </AppIconButton>
          </Box>
        ),
      },
    ],
  ];

  const shortMobileOptions: RowSchemaProps<EnrichedPayslipsProps>[] = [
    {
      field: 'tax_year_end',
      valueFormatter: row => getTaxPeriod(row.tax_week_end, row.tax_year_end),
    },
    {
      field: 'total_pay',
      valueFormatter: row => currencyFormatter.format(row.total_pay),
    },
  ];

  const downloadPdf = async (id: string) => {
    try {
      const res = await getPayslipsPDFRequest(id);
      generateFileLinkAndSave(res);
    } catch (e) {
      handleServerError(e);
    }
  };

  const getPayslips = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await getPayslipsRequest({
        data: { offset, limit: GRID_PAGE_SIZE },
      });
      setLoading(false);
      setPayslips(data.items.map((item, i) => ({ ...item, id: String(i) })));
      setTotal(data.count);
    } catch (e) {
      setLoading(false);
    }
  }, [offset]);

  const paginationOptions = useMemo(
    () => ({
      page,
      pageSize: GRID_PAGE_SIZE,
      pageCount,
      rowCount: total,
      setPage: handlePageChange,
    }),
    [page, pageCount, total, handlePageChange]
  );

  useEffect(() => {
    getPayslips();
  }, [getPayslips]);

  return (
    <WidgetWrapper {...props}>
      {isMobile ? (
        <AppMobileDataGrid
          rows={payslips}
          rowsSchema={mobileOptions}
          loading={loading}
          shortSchema={shortMobileOptions}
          paginationOptions={paginationOptions}
          columnNames={mobileColumnNames}
          withItemBorder
        />
      ) : (
        <AppDataGrid
          rows={payslips}
          loading={loading}
          height="100%"
          getRowId={row => row.pay_run_line_id}
          columns={columns}
          rowCount={total}
          paginationMode="server"
          pageSize={GRID_PAGE_SIZE}
          rowsPerPageOptions={[GRID_PAGE_SIZE]}
          onPageChange={handlePageChange}
          disableSelectionOnClick
          noPaper
          unit="payments"
        />
      )}
    </WidgetWrapper>
  );
};
