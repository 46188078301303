import { useContext } from 'react';

import { sendEmailVerificationRequest } from '@vyce/core/src/api/auth';
import { CheckerWidget } from '@vyce/core/src/components/CheckerWidget';
import { WidgetWrapperProps } from '@vyce/core/src/components/Dashboard/WidgetWrapper';

import { NotificationContext } from '../../contexts/notificationContext';

export const EmailVerificationWidget = ({
  userId,
  getReCaptchaToken,
  ...rest
}: {
  userId: string;
  getReCaptchaToken: () => Promise<string>;
} & WidgetWrapperProps) => {
  const { handleServerError, showNotification } = useContext(NotificationContext);

  const sendEmailVerification = async () => {
    try {
      const recaptcha_token = await getReCaptchaToken();
      await sendEmailVerificationRequest({ userId, recaptcha_token });
      showNotification({ message: 'Verification link has been sent', options: { variant: 'success' } });
    } catch (e) {
      handleServerError(e);
    }
  };

  return (
    <CheckerWidget
      title="Your email hasn’t been verified."
      isActionButton={true}
      action={sendEmailVerification}
      subtitle="To make sure you get emails from Vyce, please click on the verification link in the email we sent you."
      redirectTo="/profile/documents"
      type="error"
      buttonText="Send Verification Link Again"
      {...rest}
    />
  );
};
