import React, { useEffect } from 'react';

import clsx from 'clsx';
import { Theme } from '@material-ui/core/styles';
import { Box, Card, createStyles, makeStyles, Tooltip, Typography, useTheme } from '@material-ui/core';
import { VscClose } from 'react-icons/vsc';
import { CgArrowsExpandRight, CgCompressLeft } from 'react-icons/cg';
import debounce from 'lodash/debounce';

import { useFullscreenStatus } from '../../hooks';
import { AppIconButton } from '../AppIconButton';

const SMALL_WIDTH = 600;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
      display: 'flex',
      gridGap: theme.spacing(3),
      padding: theme.spacing(3),
      borderRadius: '8px',
      flexDirection: 'column',
      position: 'relative',
    },
    name: {
      marginBottom: 0,
      cursor: 'pointer',
      fontWeight: 600,
      flex: 1,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
  })
);

export interface WidgetWrapperProps {
  onRemoveItem: Function;
  closable: boolean;
  system_name: string;
  name: string;
  isResizable: boolean;
  title_color: 'primary' | 'secondary' | 'textPrimary';
}

export const WidgetWrapper = ({
  onRemoveItem,
  closable,
  children,
  system_name,
  name,
  title_color,
  isResizable,
  withExpandButton = true,
  renderExtraControls,
  setSmallViewStatus,
}: WidgetWrapperProps & {
  children: React.ReactNode;
  withExpandButton?: boolean;
  renderExtraControls?: (props: { handleFullscreenStatusChange: () => void }) => React.ReactNode;
  setSmallViewStatus?: (isSmallView: boolean) => void;
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullscreenRef = React.useRef(null);
  const { isFullscreen, setFullscreen, exitFullscreen, isFullScreenSupported } =
    useFullscreenStatus(fullscreenRef);

  const handleFullscreenStatusChange = () => {
    if (isFullscreen) {
      exitFullscreen();
    } else {
      setFullscreen();
    }
  };

  useEffect(() => {
    const observeTarget = fullscreenRef.current;
    if (!observeTarget) return;

    const debouncedCallback = debounce(entry => {
      const { width } = entry.contentRect;
      if (width >= SMALL_WIDTH) {
        setSmallViewStatus?.(false);
        return;
      }
      setSmallViewStatus?.(true);
    }, 300);

    const resizeObserver = new ResizeObserver(entries => {
      if (!Array.isArray(entries) || !entries.length) return;

      const entry = entries[0];
      debouncedCallback(entry);
    });

    resizeObserver.observe(observeTarget);

    return () => resizeObserver.unobserve(observeTarget);
  }, [fullscreenRef]);

  return (
    <Box height="100%" overflow="hidden">
      <Card ref={fullscreenRef} id={system_name} className={classes.root} variant="outlined">
        <Box display="flex" alignItems="center" gridGap={8} flexWrap="nowrap">
          <Tooltip title={name} placement="top">
            <Typography
              variant="h6"
              color={title_color || 'textPrimary'}
              className={clsx(classes.name, 'draggable-widget-header')}
              gutterBottom>
              {name}
            </Typography>
          </Tooltip>

          <Box
            display="flex"
            flexBasis="72px"
            flexGrow={0}
            flexShrink={0}
            justifyContent="flex-end"
            gridGap={8}>
            {renderExtraControls?.({ handleFullscreenStatusChange })}
            {isFullScreenSupported && isResizable !== false && withExpandButton && (
              <Tooltip title="Fullscreen">
                <AppIconButton variant="paper" isSmall isBorder onClick={handleFullscreenStatusChange}>
                  {isFullscreen ? (
                    <CgCompressLeft size="16px" color={theme.palette.text.primary} />
                  ) : (
                    <CgArrowsExpandRight size="16px" color={theme.palette.text.primary} />
                  )}
                </AppIconButton>
              </Tooltip>
            )}

            {!isFullscreen && closable && (
              <Box>
                <Tooltip title="Delete">
                  <AppIconButton variant="paper" isSmall isBorder onClick={() => onRemoveItem(system_name)}>
                    <VscClose size="20px" color={theme.palette.text.primary} />
                  </AppIconButton>
                </Tooltip>
              </Box>
            )}
          </Box>
        </Box>

        <Box
          width="100%"
          height="100%"
          overflow="visible"
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="center">
          {children}
        </Box>
      </Card>
      <Box
        width="100%"
        height={24}
        bgcolor={theme.palette.background.paper}
        position="sticky"
        bottom={0}
        borderRadius="0 0 8px 8px"
      />
    </Box>
  );
};
