import React from 'react';

import { Box, Button, Typography } from '@material-ui/core';
import { AiOutlinePlus } from 'react-icons/ai';
import clsx from 'clsx';

import { ActivateSubscriptionDialog, CustomizeDashboard } from '@vyce/core/src/components';
import { AppDashboard } from '@vyce/core/src/components/Dashboard/AppDashboard';
import { WidgetItem } from '@vyce/core/src/types';
import { useHorizontalScrollStyles } from '@vyce/core/src/styles';

import { UniversalInviteModule, WidgetModule } from 'src/modules';
import { usePayrollDashboardData } from './hooks';

export const PayrollDashboardModule: React.FC = () => {
  const horizontalScrollClasses = useHorizontalScrollStyles();

  const {
    isMobile,
    first_name,
    loading,
    isActivateSubscriptionDialogOpen,
    payDashboard,
    isInviteFormOpen,
    selectedCompany,
    onLayoutChange,
    onWidgetStatusChange,
    closeActivateSubscriptionDialog,
    simpleSubscribe,
    handleInviteButtonClick,
    setCloseInviteForm,
  } = usePayrollDashboardData();

  const getWidget = (widget: WidgetItem) => {
    return { component: WidgetModule, props: { widget } };
  };

  return (
    <>
      <Box
        className={clsx(
          horizontalScrollClasses.blockWrapper,
          horizontalScrollClasses.blockWithHideScroll,
          horizontalScrollClasses.navBarBlock
        )}>
        <Box display="flex">
          {!isMobile && (
            <>
              <Typography
                variant="subtitle1"
                style={{ marginRight: 4, fontWeight: 600, whiteSpace: 'nowrap' }}
                color="secondary">
                Hey {first_name},
              </Typography>
              <Typography variant="subtitle1" color="textSecondary" style={{ whiteSpace: 'nowrap' }}>
                here’s your Payroll dashboard!
              </Typography>
            </>
          )}
        </Box>

        <Box display="flex" alignItems="center" gridGap={16}>
          <Button
            onClick={handleInviteButtonClick}
            size="small"
            data-tour="invite"
            startIcon={<AiOutlinePlus />}
            variant="contained"
            color="primary">
            Invite New Members
          </Button>

          <CustomizeDashboard
            items={payDashboard?.widgets || []}
            onWidgetStatusChange={onWidgetStatusChange}
          />
        </Box>
      </Box>

      <Box style={{ marginRight: '-24px', marginLeft: '-24px' }}>
        <AppDashboard
          layouts={payDashboard?.layouts}
          onLayoutChange={onLayoutChange}
          widgets={payDashboard?.widgets || []}
          getWidget={getWidget}
          onWidgetStatusChange={onWidgetStatusChange}
        />
      </Box>
      <ActivateSubscriptionDialog
        open={isActivateSubscriptionDialogOpen}
        onClose={closeActivateSubscriptionDialog}
        callback={simpleSubscribe}
        loading={loading}
        subscriptionTextMode="self"
        wasSubscribed={selectedCompany?.subscribed_company}
      />
      <UniversalInviteModule isOpen={isInviteFormOpen} onClose={setCloseInviteForm} />
    </>
  );
};
