import React, { useContext, useEffect, useMemo, useState } from 'react';

import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Box, useTheme, Typography } from '@material-ui/core';
import { FiChevronRight } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import uniqBy from 'lodash/uniqBy';

import { AppLinearProgress } from '@vyce/core/src/components/AppLinearProgress';
import { backgrounds, getTextColor } from '@vyce/core/src/theme/styles';
import { ColorThemeContext } from '@vyce/core/src/contexts';
import { AppCheckboxRow, AppIconButton } from '@vyce/core/src/components';
import { SurveyContent, TagGroups } from '@vyce/core/src/types';
import { getSurveyAnswers, getSurveysRequest } from '@vyce/core/src/api/survey';
import { SEARCH_HEALTH_SURVEY_SUBSTRING } from '@vyce/core/src/views/profile/user/H&S';
import { WidgetWrapper, WidgetWrapperProps } from '@vyce/core/src/components/Dashboard/WidgetWrapper';

import { useTypedSelector } from 'src/hooks';

export interface WidgetProfileItem {
  title: string;
  status: boolean;
  link: string;
}

export const CompleteProfileWidget = (props: WidgetWrapperProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const { user } = useTypedSelector(state => state);
  const { colorTheme } = useContext(ColorThemeContext);
  const isDarkMode = colorTheme === 'dark';
  const textColor = getTextColor(isDarkMode);
  const history = useHistory();
  const [widgetProfileItems, setWidgetProfileItems] = useState<WidgetProfileItem[]>([]);
  const [isHealthAndSafetyCompleted, setIsHealthAndSafetyCompleted] = useState<boolean>(false);

  const progress = useMemo(() => {
    const completedElements = widgetProfileItems.filter(item => !!item.status).length;
    return Math.floor((completedElements / widgetProfileItems.length) * 100);
  }, [widgetProfileItems]);

  const getSurvey = async () => {
    try {
      const surveysRes = await getSurveysRequest(SEARCH_HEALTH_SURVEY_SUBSTRING);
      const surveyRes = surveysRes.data?.items[0];
      if (surveyRes) {
        const res = await getSurveyAnswers(surveyRes.uuid);
        const allQuestionsAnswered = res.data.content.every((item: SurveyContent) => {
          return item.answer?.yes_no !== null;
        });
        setIsHealthAndSafetyCompleted(allQuestionsAnswered);
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (user) {
      getSurvey();
      const skills = user.worker?.tags?.filter(tag => tag.group === TagGroups.SKILL);

      const items: WidgetProfileItem[] = [
        {
          title: 'Email Verification',
          status: !!user.email_verified,
          link: 'profile/personal',
        },
        {
          title: 'Phone Verification',
          status: !!user.phone_verified,
          link: 'profile/personal',
        },
        {
          title: 'Skills',
          status: !!skills?.length,
          link: 'profile/professional',
        },
        {
          title: 'Work Experience',
          status: !!user.worker?.positions?.length,
          link: 'profile/professional',
        },
        {
          title: 'Bio',
          status: !!user.worker?.biography,
          link: 'profile/professional',
        },
        {
          title: 'ID Verification',
          status: !!user.id_verification?.verified,
          link: 'profile/documents',
        },
        {
          title: 'Profile picture',
          status: !!user.photo?.url,
          link: 'profile/personal',
        },
        {
          title: 'Right to Work',
          status: !!user.rtw_verification?.verified,
          link: 'profile/documents',
        },
        {
          title: 'Personal Details',
          status:
            !!user.address &&
            !!user.context?.ni_number &&
            !!user.date_of_birth &&
            !!user.nationality &&
            !!user.gender,
          link: 'profile/personal',
        },
      ];
      if (user.employee) {
        items.push({
          title: 'Bank Details',
          status: !!user.employee?.bank_account?.account_number,
          link: 'profile/employment',
        });
      }
      setWidgetProfileItems(value => [...items, ...value]);
    }
  }, [user.qualifications]);

  useEffect(() => {
    if (isHealthAndSafetyCompleted) {
      setWidgetProfileItems(value => [
        ...value,
        {
          title: 'Health Questionnaire',
          status: isHealthAndSafetyCompleted,
          link: 'profile/medical',
        },
      ]);
    }
  }, [isHealthAndSafetyCompleted]);

  useEffect(() => {
    if (user.qualifications) {
      setWidgetProfileItems(value => [
        ...value,
        {
          title: 'Qualifications',
          status:
            !!user.qualifications?.courses?.count ||
            !!user.qualifications?.tests?.count ||
            !!user.qualifications?.certificates?.count,
          link: 'profile/qualifications',
        },
      ]);
    }
  }, [user.qualifications]);

  return (
    <WidgetWrapper {...props}>
      <Box width="100%" height="100%" overflow="hidden">
        <AppLinearProgress
          variant="determinate"
          value={progress}
          classes={{ bar: classes.bar, root: classes.progress }}
        />
        <Typography className={classes.title} style={{ color: textColor.medium }}>
          Your profile is {progress}% complete.
        </Typography>

        <Box
          marginTop={'20px'}
          overflow="auto"
          className={classes.container}
          display="flex"
          flexDirection="column">
          {uniqBy(widgetProfileItems, 'title').map(item => (
            <AppCheckboxRow checked={item.status} label={item.title} handleChange={() => {}}>
              {!item.status ? (
                <AppIconButton
                  variant="primary"
                  disableFocusRipple
                  disableRipple
                  disableTouchRipple
                  onClick={() => history.push(item.link)}>
                  <FiChevronRight size={24} color={theme.palette.text.primary} />
                </AppIconButton>
              ) : (
                <></>
              )}
            </AppCheckboxRow>
          ))}
        </Box>
      </Box>
    </WidgetWrapper>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bar: {
      backgroundImage: 'linear-gradient(270deg, #0F5EF9 0.02%, #2FECE6 100%)',
    },
    progress: {
      width: '100%',
      borderRadius: '8px',
      height: '12px',
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
      backgroundColor: theme.palette.type === 'dark' ? backgrounds.darkInput : backgrounds.input,
    },
    title: {
      fontSize: '15px',
      lineHeight: '10px',
    },
    container: {
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: '8px',
      flex: 1,
      maxHeight: 'calc(100% - 60px)',
    },
  })
);
